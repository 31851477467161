const isNonLocalEnv = process.env.APP_ENV && ['dev', 'qa', 'production', 'beta'].includes(process.env.APP_ENV);
const calculatedBaseURL = process.env.BASE_URL || '/';
module.exports = {
  server: {
    host: '0.0.0.0',
    port: 3001
  },
  telemetry: false,
  modern: true,
  target: 'server',
  ssr: true,
  publicRuntimeConfig: {
    anetAcceptJs: process.env.ANET_ACCEPT_JS_URL,
    anetAcceptUi: process.env.ANET_ACCEPT_UI,
    anetAcceptRequestUrl: process.env.ANET_REQUEST_URL,
    anetClientKey: process.env.ANET_CLIENT_KEY,
    anetLoginId: process.env.ANET_LOGIN_ID,
    env: process.env.APP_ENV || 'local',
    TrueFireUrl: process.env.TF_URL || 'https://truefire.com',
  },
  env: {
    appEnv: process.env.APP_ENV || 'local',
    nexusUrl: process.env.NEXUS_URL || '/tfs/api/nexus/',
    configsUrl: process.env.CONFIGS_URL || '/tfs/api/configs',
    baseURL: process.env.BASE_URL || '/',
    tfsApiUrl: process.env.TFS_API_URL || '/tfs/api/',
    TrueFireUrl: process.env.TF_URL || 'https://truefire.com',
  },
  loading: false,
  head: {
    title: 'Online Guitar Lessons - Learn Guitar with the Best Online Guitar Lessons and Guitar Teachers - TrueFire',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'appleid-signin-client-id', content: 'com.truefire.applesignin' },
      { name: 'appleid-signin-scope', content: 'email' },
      { name: 'appleid-signin-redirect-uri', content: 'https://truefire.com/online-guitar-lessons' },
      { name: 'appleid-signin-state', content: 'origin:web' },
      { name: 'fb:app_id', content: '143023449058870' },
      { name: 'robots', content: 'index' },
      { name: 'apple-itunes-app', content: 'app-id=690143001' },
      { name: 'og:title', content: 'Online Guitar Lessons - TrueFire' },
      { name: 'og:url', content: 'https://truefire.com' },
      // { name: 'og:description', content: "Learn how to play guitar with free online guitar lessons and interactive video guitar courses at TrueFire, the world's most comprehensive library of guitar instruction" },
      { name: 'og:type', content: 'website' },
      { name: 'facebook-domain-verification', content: '9cdrz60me3c61wl42zrq747moougpl' },
      { name: 'google-signin-client_id', content: '185148738742-0723kgb7no0jc0iofhoam2v6mr3jsqo3.apps.googleusercontent.com' },
      { name: 'ahrefs-site-verification', content: 'ec6ce77380f840610b9343e8ab8c02f321e0b96f7a1b5a8c278fa10bdfee3099' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@truefiretv' },
      { name: 'twitter:creator', content: '@truefiretv' },
      { name: 'twitter:title', content: 'Online Guitar Lessons - TrueFire' },
      // { name: 'twitter:description', content: "Learn how to play guitar with free online guitar lessons and interactive video guitar courses at TrueFire, the world's most comprehensive library of guitar instruction." },
    ],
    script: [
      {
        src: `https://staticw2.yotpo.com/4otGQGQZiVamGX22LVlmFeddVLn9oJKVoK0KHnyc/widget.js`,
        defer: true
      },
      {
        src: 'https://cmp.osano.com/xxTCs6jSBH/65284991-b117-4f6b-a7c1-8a634114d3ce/osano.js',
        condition: 'window.location.hostname.includes("truefire.com")'
      }
    ],
    link: [
      { rel: 'icon', type: 'image/x-icon', href: 'https://cdn.truefire.com/images/favicon.ico' },
      {
        rel: 'preload',
        href: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: true
      },
      {
        rel: 'preload',
        href: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: true
      },
      {
        rel: 'preload',
        href: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2',
        as: 'font',
        type: 'font/woff2',
        crossorigin: true
      }
    ]
  },
  css: [
    '@/assets/tf',
    '@fortawesome/fontawesome-svg-core/styles.css'
  ],
  plugins: [
    "~/plugins/tfs.js",
    "~/plugins/nexus.js",
    "~/plugins/search.js",
    "~/plugins/models/member.js",
    "~/plugins/models/cart.js",
    "~/plugins/models/educator.js",
    "~/plugins/models/configs.js",
    "~/plugins/models/player.js",
    "~/plugins/models/course.js",
    "~/plugins/models/itj.js",
    "~/plugins/models/item.js",
    { src: '~/middleware/plusnetwork', mode: 'client' },
  ],
  performance: {
    gzip: true
  },
  components: true,
  modules: [
    'cookie-universal-nuxt',
    '@nuxtjs/axios',
    '@nuxtjs/fontawesome',
    '@nuxtjs/component-cache',
    'nuxt-ssr-cache',
  ],
  cache: {
    pages: ['.*'],
    key(route, context) {
      
      if (!process.env.APP_ENV) return false;
      if (process.env.APP_ENV === 'local') return false;
      if (context.res && context.res.statusCode !== 200) {
        return false;
      }
      let prefix = '';

      if (process.env.APP_ENV === 'production') prefix = 'p:pages:';
      if (process.env.APP_ENV === 'qa') prefix = 'q:pages:';
      if (process.env.APP_ENV === 'beta') prefix = 'b:pages:';
      if (process.env.APP_ENV === 'dev') prefix = 'd:pages:';

      if (process.env.APP_ENV !== 'production') {
        let ip = context.req.headers['x-forwarded-for'] || context.req.connection.remoteAddress || '';
        if (ip.includes(',')) {
          ip = ip.split(',')[0];
        }
        ip = ip.trim().replace(/\s/g, '');  // Ensure no spaces in IP
        prefix += `${ip}`;
      }

      // Use the context.modern variable to include the bundle type in the cache key
      prefix += `:${context.modern ? 'modern' : 'legacy'}`;

      return `${prefix}${route}`;
    },
    store: {
      type: 'redis',
      host: 'tfs-production-redis.rfswfg.ng.0001.use1.cache.amazonaws.com',
      port: 6379,
      ttl: process.env.APP_ENV === 'production' ? 60 * 60 * 24 : 60 * 5,  // 24 hours for production, 5 minutes for others
    }
  },
  axios: {
    baseURL: '/',
  },
  router: {
    middleware: ['memberDataRefresh', 'tfs'],
    base: calculatedBaseURL,
    prefetchLinks: true,
  },
  serverMiddleware: [
    (req, res, next) => {
      res.setHeader('Cross-Origin-Opener-Policy', 'same-origin-allow-popups');
      next();
    },
  ],
  build: {
    extend(config, { isDev, isClient }) {
      if (isDev && isClient) {
        config.devtool = 'eval-source-map';
      }
    },
    // Conditionally apply settings only for non-local environments
    ...(isNonLocalEnv && {
      // Extract CSS into separate files for better performance in production
      extractCSS: true,
      // Extend webpack configuration
      extend(config, { isClient }) {
        if (isClient) {
          // Enable source maps for better debugging on the client side
          config.devtool = 'source-map';
        }
      },
      // Optimization settings for webpack
      optimization: {
        // Minimize the code to reduce bundle size
        minimize: true,
        // Create a single runtime chunk to avoid duplicated dependencies
        runtimeChunk: 'single',
        // Split chunks for better caching and loading
        splitChunks: {
          chunks: 'all',
          minSize: 30000,
          maxSize: 0,
          minChunks: 1,
          maxAsyncRequests: 5,
          maxInitialRequests: 3,
          automaticNameDelimiter: '-',
          cacheGroups: {
            defaultVendors: {
              test: /[\\/]node_modules[\\/]/,
              priority: -10
            },
            default: {
              minChunks: 2,
              priority: -20,
              reuseExistingChunk: true
            },
            styles: {
              name: 'styles',
              test: /\.css$/,
              chunks: 'all',
              enforce: true,
            }
          }
        }        
      }
    }),
    // Define the public path for the build assets
    publicPath: `https://cdn.truefire.com/builds/${process.env.APP_ENV}/_nuxt/`,
  },
  render: {
    bundleRenderer: {
      runInNewContext: false, // recommended
      shouldLog: false // enable detailed SSR logs
    },
    static: {
      setHeaders(res) {
        res.setHeader('Cache-Control', 'no-store')
      },
      maxAge: '1y',
      cacheControl: 'public, immutable'
    },
    resourceHints: true,
  },
};